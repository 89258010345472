import React from "react";
import clsx from "clsx";
import { get } from "lodash";
import { Link } from "react-router-dom";
import Helpers from "../../../../utils/Helpers";

const TopLinks = ({ className, topLinks }) => (
  <div className={clsx("TopLinks__wrapper", className)}>
    <div className="TopLinks">
      {topLinks.map(({ links, title, url }) => {
        const hasNestedLinks = !url && Boolean(get(links, "length"));
        const linkClassName = "TopLinks__link";

        return (
          <div className="TopLinks__link__wrapper" key={title}>
            {!hasNestedLinks && !Helpers.linkChecker(url) ? (
              <Link className={linkClassName} to={url}>
                {title}
              </Link>
            ) : !hasNestedLinks && Helpers.linkChecker(url) ? (
              <a href={url} className={linkClassName} key={title}>
                {title}
              </a>
            ) : (
              <>
                <span className={linkClassName}>{title}</span>
                <div className="TopLinks__nestedLinks__wrapper">
                  <div className="TopLinks__nestedLinks">
                    {links &&
                      links.map(({ title, url }) => (
                        <div key={title}>
                          {Helpers.linkChecker(url) ? (
                            <a
                              key={title}
                              href={url}
                              target="_blank"
                              className="TopLinks__nestedLink"
                              rel="noreferrer"
                            >
                              {title}
                            </a>
                          ) : (
                            <Link
                              className="TopLinks__nestedLink"
                              key={title}
                              to={url}
                            >
                              {title}
                            </Link>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  </div>
);

export default TopLinks;
